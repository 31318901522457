import React from "react";
import { Link } from "gatsby";
import { string } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

const JobItem = ({ headline, paragraph, linkTo }) => {
  const intl = useIntl();

  return (
    <div className="col-12 col-md-6 mb-3 reveal jobs-item">
      <div className="bg-alternate bg-monday-green text-color-white h-100 p-3 d-flex flex-column justify-content-between align-items-start">
        <div className="mb-3 w-100">
          <h3 className="h4">{headline}</h3>
          <p>{paragraph}</p>
        </div>
        <Link
          to={`${intl.formatMessage({
            id: "nav.jobs.link",
          })}${intl.formatMessage({ id: "nav.jobs.link.ext" })}/${linkTo}`}
          className="btn"
        >
          <FormattedMessage id="jobs.more.text" />
        </Link>
      </div>
    </div>
  );
};

JobItem.propTypes = {
  headline: string.isRequired,
  paragraph: string.isRequired,
  linkTo: string.isRequired,
};

export default JobItem;
